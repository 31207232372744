// import firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default function firebaseInit() {
  const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDSNp7_VajsPYyduZZ1-j-afHzWhhT0bK0",
    authDomain: "sendmore-f53cf.firebaseapp.com",
    projectId: "sendmore-f53cf",
    storageBucket: "sendmore-f53cf.appspot.com",
    messagingSenderId: "407427156749",
    appId: "1:407427156749:web:f54de8863204094ae4fe44",
    measurementId: "G-MJDYBMEF98",
  });

  return firebaseApp.firestore();
}
