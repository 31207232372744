import Vue from "vue";
import App from "./App.vue";
import Textra from "vue-textra";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import apolloProvider from "./plugins/apollo";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import firebaseInit from "./firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import "firebase/compat/database";

// TODO: Convert to plugin
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyAZpnFIkebY_b-A1R0aRmr7oESGlg2euW0",
});

Vue.use(Textra);

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     Vue,
//     dsn:
//       "https://1963fe01b2184f09aca4fb6b268f3141@o1064500.ingest.sentry.io/6055366",
//     integrations: [
//       new Integrations.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ["localhost", "annuitycheck.com", /^\//],
//       }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.5,
//     logErrors: true,
//   });
// }

Vue.prototype.$db = firebaseInit();

Vue.config.productionTip = false;

function parseJwt(token) {
  if (!token) {
    return null;
  }
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function updateAuthInfo() {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    const currentTime = Math.ceil(new Date().getTime() / 1000);
    const expire = store.state.expire;
    const fiveMinutes = 300;
    const refresh = expire && expire - fiveMinutes < currentTime;
    console.log("refresh", refresh);
    if (refresh) {
      currentUser.getIdToken(refresh).then((accessToken) => {
        let data = parseJwt(accessToken);
        store.commit("setExpire", data.exp);
        console.log("refresh", refresh);
        localStorage.setItem("token", accessToken);
      });
    }
  }
}

setInterval(function() {
  updateAuthInfo();
}, 300 * 1000); // 5 minutes

store.commit("setLoading", true);
firebase.auth().onAuthStateChanged((user) => {
  console.log("auth state changed", user);
  if (user) {
    if (localStorage.getItem("token")) {
      let token_val = parseJwt(localStorage.getItem("token"));
      if (!token_val) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      if (token_val.exp < Date.now() / 1000) {
        localStorage.removeItem("token");
        user.getIdToken(true).then((token) => {
          localStorage.setItem("token", token);
        });
      }
      if (token_val["https://hasura.io/jwt/claims"] === undefined) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      store.dispatch("postFireBaseSignin", user).then(() => {
        store.commit("setLoading", false);
      });
    } else {
      user.getIdToken(true).then((token) => {
        let token_val = parseJwt(token);
        if (token_val["https://hasura.io/jwt/claims"] === undefined) {
          localStorage.removeItem("token");
          window.location.reload();
        }
        localStorage.setItem("token", token);
        store.dispatch("postFireBaseSignin", user).then(() => {
          store.commit("setLoading", false);
        });
      });
    }
    let data = parseJwt(localStorage.getItem("token"));
    store.commit("setExpire", data.exp);
  } else {
    localStorage.getItem("token") && localStorage.removeItem("token");
    store.commit("setUser", {
      token: null,
      is_login: false,
      id: null,
      acl: null,
      admin: false,
      name: "",
      email: "",
    });
    store.commit("setLoading", false);
  }
});

import "./plugins/vuetify-money.js";
import "./plugins/vuetify-mask.js";
import "./plugins/vue-donut.js";
import "./assets/global.css";
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// eslint-disable-next-line no-unused-vars
import helpers from "./mixins/helpers";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
