<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" fixed temporary dark right clipped>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item>
            <v-img
              alt="AnnuityCheck"
              class="mr-2 mt-5 mb-5"
              contain
              src="@/assets/MainLogoInverted.png"
              transition="scale-transition"
              width="120"
            />
          </v-list-item>

          <v-list-item
            link
            v-for="(tab, index) in $store.state.user.is_login || assume_login
              ? loggedInMenu
              : loggedOutMenu"
            :key="index"
            :to="tab.custom_action ? '' : tab.to"
            @click.stop="tab.custom_action ? tab.custom_action() : null"
          >
            <v-list-item-icon>
              <v-icon>{{ tab.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ tab.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-if="$store.state.user.admin">
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3>Admin</h3>
            <v-list-item
              link
              v-for="(tab, index) in admin"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-icon>
                <v-icon>{{ tab.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ tab.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <div
        class="d-flex align-center"
        style="height:100%"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <router-link to="/">
          <v-img
            alt="AnnuityCheck"
            class="mr-2"
            contain
            src="@/assets/MainLogoDark.png"
            transition="scale-transition"
            width="220"
            height="100%"
          />
        </router-link>
      </div>
      <div class="d-flex align-center" style="height:100%" v-else>
        <router-link to="/" style="height:100%">
          <v-img
            alt="AnnuityCheck"
            contain
            width="50"
            style="height:100%"
            src="@/assets/MainIcon.png"
            transition="scale-transition"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-tabs right optional v-else v-model="tab">
        <v-tab
          v-for="(tab, index) in $store.state.user.is_login || assume_login
            ? loggedInMenu
            : loggedOutMenu"
          :key="index"
          :to="tab.custom_action ? '' : tab.to"
          @click.stop="tab.custom_action ? tab.custom_action() : null"
        >
          {{ tab.text }}
        </v-tab>
        <v-menu bottom offset-y open-on-hover v-if="$store.state.user.admin">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
              Admin
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(tab, index) in admin"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-content>
                <v-list-item-title> {{ tab.text }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
    </v-app-bar>

    <v-main v-if="$route.path == '/'">
      <router-view />
    </v-main>
    <v-main v-else-if="!$store.state.loading">
      <router-view />
    </v-main>
    <v-main v-else>
      <!-- loading -->
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            color="primary"
            size="100"
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-main>

    <v-snackbar v-model="$store.state.snackbar.show">
      {{ $store.state.snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  name: "App",
  created() {},
  mounted() {
    if (localStorage?.dark === "true") {
      this.$vuetify.theme.dark = true;
      localStorage.dark = "true";
    } else {
      this.$vuetify.theme.dark = false;
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  },
  methods: {
    signout() {
      // console.log(this.$firebase);
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem("token");
          this.$store.commit("signout");
          window.location.href = "/";
        });
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
    $route(to) {
      if (to.path.includes("admin")) {
        this.$nextTick(() => {
          this.tab = null;
        });
      }
    },
  },
  data() {
    return {
      assume_login: localStorage?.token ? true : false,
      logo_prefix: "ac",
      company_name: "AnnuityCheck",
      tab: null,
      drawer: false,
      group: null,
      admin: [
        {
          text: "Agents",
          icon: "mdi-account",
          to: "/admin/agents",
        },
        {
          text: "Campaigns",
          icon: "mdi-email-newsletter",
          to: "/admin/seminars",
        },
        {
          text: "Bootcamp Events",
          icon: "mdi-calendar",
          to: "/admin/events",
        },
      ],
      loggedOutMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        // {
        //   text: "Markets",
        //   icon: "mdi-magnify-plus-outline",
        //   to: "/markets",
        // },
        // {
        //   text: "Pricing",
        //   icon: "mdi-currency-usd",
        //   to: "/pricing",
        // },
        // {
        //   text: "Contact Us",
        //   icon: "mdi-send",
        //   to: "/contact-us",
        // },
        {
          text: "Sign In",
          icon: "mdi-login",
          to: "/login",
        },
      ],
      loggedInMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        {
          text: "Campaigns",
          icon: "mdi-email-newsletter",
          to: "/seminar",
        },
        {
          text: "Account",
          icon: "mdi-account",
          to: "/account",
        },
        {
          text: "Sign Out",
          icon: "mdi-logout",
          to: "/",
          custom_action: this.signout,
        },
      ],
      //
    };
  },
};
</script>
