// import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
import Vue from "vue";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";

Vue.use(VueApollo);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

const getHeaders = () => {
  const headers = {};
  const token = window.localStorage.getItem("token");
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return headers;
};
// Create an http link:
const link = new HttpLink({
  uri: "https://sendmore.hasura.app/v1/graphql",
  fetch,
  headers: getHeaders(),
});
const client = new ApolloClient({
  link: authMiddleware.concat(link),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

// export const apolloClient = new ApolloClient({
//   uri: "https://sendmore.hasura.app/v1/graphql",
//   headers: {
//     "content-type": "application/json",
//     ...(localStorage.getItem("token") && {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     }),
//   },
// });

export default new VueApollo({
  defaultClient: client,
});
