<template>
  <v-container fluid>
    <v-row class="text-center py-4 my-3 px-6">
      <v-col cols="12" md="12" class="mx-auto">
        <div class="shell">
          <h1>
            It's time to
            <span class="verb">convert</span> more
          </h1>
        </div>
        <v-img
          style="width: 380px; max-width: 80vw"
          class="mx-auto my-4 mb-6"
          src="@/assets/img/ud_predictive_analytics.svg"
        ></v-img>
        <p class="intro" style="font-family: Basetica-black; font-size: 28px">
          Enjoy more of what matters.
        </p>
        <br />
        <v-row>
          <v-col cols="12" sm="8" class="text-center mx-auto">
            <v-btn
              v-if="!$store.state.user.id"
              x-large
              class="mb-6 mt-0"
              color="primary"
              dark
              rounded
              to="/signup"
            >
              Get Started
            </v-btn>
            <v-btn
              v-else
              x-large
              class="my-auto"
              color="primary"
              dark
              rounded
              to="/presentation"
            >
              Create Campaign
            </v-btn>

            <!-- <v-text-field
              style="width: 400px; max-width: 80vw;"
              class="shrink mx-auto zip-input"
              outlined
              label="ZIP Code"
              prepend-inner-icon="mdi-map-marker"
            >
              <template v-slot:append-outer class="my-auto">
                <v-btn large class="my-auto" color="#003a6d" dark to="/signup">
                  Get Started
                </v-btn>
              </template>
            </v-text-field> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="lightblue pt-6 px-6">
      <v-col cols="12" md="6" class="mx-auto text-center">
        <h2>Order 100% Online</h2>
        <!-- <hr
          class="solid mx-auto mb-3 mt-3"
          style="
            width: 100px;
            background-color: #37254c;
            border: none;
            height: 2px;
          "
        /> -->
        <p class="intro">
          Direct mail marketing has never been easier.
        </p>
      </v-col>
    </v-row>
    <v-row class="lightblue pb-6 text-center px-6">
      <!-- <v-row class="hidden-sm-and-down">
        <v-col>
          <v-img
            src="@/assets/icons/Calendar-Settings.svg"
            height="50"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          >
          </v-img>
        </v-col>
        <v-col>
          <v-img
            src="@/assets/icons/Collaboration-Global.svg"
            height="50"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          >
          </v-img>
        </v-col>
        <v-col>
          <v-img
            src="@/assets/icons/Metrics.svg"
            height="53"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          />
        </v-col>
      </v-row> -->
      <v-row class="text-center">
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/settings.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>

          <h3>Setup with ease</h3>
          <p class="explainer">
            Forget the confusion. Just choose an event date, and SendMore will
            walk you through the rest using our proven templates.
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/ancestors.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>
          <h3>Choose your demographics</h3>
          <p class="explainer">
            Select an age range and zip codes around your event. SendMore will
            generate your counts in less than 60 seconds.
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/hand.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>
          <h3>Enjoy better pricing</h3>
          <p class="explainer">
            Our automated systems save you money without reducing quality. No
            sales rep will call to upsell you. Only pay for what you need.
          </p>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="darkblue pt-6 px-6">
      <v-col cols="12" md="6" class="mx-auto text-center">
        <h2>Online Tracking</h2>
        <!-- <hr
          class="solid mx-auto mb-3 mt-3"
          style="
            width: 100px;
            background-color: #37254c;
            border: none;
            height: 2px;
          "
        /> -->
        <p class="intro">
          Direct mail marketing has never been easier.
        </p>
      </v-col>
    </v-row>
    <v-row class="darkblue pb-6 text-center px-6">
      <!-- <v-row class="hidden-sm-and-down">
        <v-col>
          <v-img
            src="@/assets/icons/Calendar-Settings.svg"
            height="50"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          >
          </v-img>
        </v-col>
        <v-col>
          <v-img
            src="@/assets/icons/Collaboration-Global.svg"
            height="50"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          >
          </v-img>
        </v-col>
        <v-col>
          <v-img
            src="@/assets/icons/Metrics.svg"
            height="53"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          />
        </v-col>
      </v-row> -->
      <v-row class="text-center">
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/registration.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>

          <h3>Online registration</h3>
          <p class="explainer">
            Capture emails and phone numbers with our online registration
            system. Automated email notifications are sent to you and the
            registrant.
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/reminder.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>
          <h3>Event Reminders</h3>
          <p class="explainer">
            Your registrants will receive reminder texts the day before the
            event and again four hours prior to the event starting to help
            maximize attendance.
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/man.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>
          <h3>Household Data</h3>
          <p class="explainer">
            All registrant information is conveniently stored in your account.
            On the day of your event, you can print appointment cards in
            1-click.
          </p>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="lightblue pt-6 px-6">
      <v-col cols="12" md="6" class="mx-auto text-center">
        <h2>Enterprise Sharepoints</h2>
        <!-- <hr
          class="solid mx-auto mb-3 mt-3"
          style="
            width: 100px;
            background-color: #37254c;
            border: none;
            height: 2px;
          "
        /> -->
        <!-- <p class="intro">
          Direct mail marketing has never been easier.
        </p> -->
      </v-col>
    </v-row>
    <v-row class="lightblue pb-6 text-center px-6">
      <!-- <v-row class="hidden-sm-and-down">
        <v-col>
          <v-img
            src="@/assets/icons/Calendar-Settings.svg"
            height="50"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          >
          </v-img>
        </v-col>
        <v-col>
          <v-img
            src="@/assets/icons/Collaboration-Global.svg"
            height="50"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          >
          </v-img>
        </v-col>
        <v-col>
          <v-img
            src="@/assets/icons/Metrics.svg"
            height="53"
            class="mx-auto mb-1 mt-5"
            width="50"
            contain
          />
        </v-col>
      </v-row> -->
      <v-row class="text-center">
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/document.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>

          <h3>Controlled Marketing</h3>
          <p class="explainer">
            Develop custom, branded mailers and funnels that only your sales
            team can access using your company’s activation code.
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/tracking.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>
          <h3>Activity Tracking</h3>
          <p class="explainer">
            You and your assigned marketers can track campaign activity across
            your company to help your sales team to optimize results at every
            step.
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            src="@/assets/img/select-all.png"
            height="50"
            class="mx-auto my-4"
            width="50"
            contain
          >
          </v-img>
          <h3>Group Pricing</h3>
          <p class="explainer">
            Based on volume, your company may qualify for group pricing, giving
            you the ability to develop custom solutions for your needs while
            enjoying the cost saving provided by our automated processes.
          </p>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-img
          src="@/assets/img/ud_all_data.svg"
          height="400"
          class="mx-auto mb-1 mt-5"
          width="400"
          contain
        ></v-img>
      </v-col>

      <v-col cols="12" md="6" class="text-center">
        <h2>Integrated Demographics.</h2>
        <hr
          class="solid mx-auto mb-3 mt-3 p-6"
          style="
                width: 100px;
                background-color: #37254c;
                border: none;
                height: 2px;
              "
        />

        <p class="intro">
          Select your demographics based on Zip-Codes or a Radius Search from
          your business or venue location. Get complete counts in seconds, not
          days.
        </p>
      </v-col>
    </v-row>

    <v-row class="lightblue">
      <v-col cols="12" md="6" class="order-md-first text-center">
        <h2>
          Online to Offline <br />
          Automation.
        </h2>
        <hr
          class="solid mx-auto mb-3 mt-3"
          style="
                width: 100px;
                background-color: #37254c;
                border: none;
                height: 2px;
              "
        />

        <p class="intro">
          Using our precision tools, you can build your campaign 100% online, in
          less than 10-minutes and save time and money by skipping the sales
          pitch.
        </p>
      </v-col>
      <v-col cols="12" md="6" class="order-first">
        <v-img
          src="@/assets/img/four-trillion.svg"
          height="400"
          class="mx-auto mb-1 mt-5"
          width="400"
          contain
        ></v-img>
      </v-col>
    </v-row>

    <v-row class="darkblue">
      <v-col cols="12" md="6">
        <v-img
          src="@/assets/img/ud_data_report.svg"
          height="400"
          class="mx-auto mb-1 mt-5"
          width="400"
          contain
        ></v-img>
      </v-col>
      <v-col cols="12" md="6" class="text-center">
        <h2>
          Easy to Use <br />
          Tracking Tools.
        </h2>
        <hr
          class="solid mx-auto mb-3 mt-3"
          style="
                width: 100px;
                background-color: white;
                border: none;
                height: 2px;
              "
        />

        <p class="intro">
          SendMore invented a unique 7-digit code to make tracking your
          prospects with complete access to their information easier than ever.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
* {
  list-style: none;
  border: 0;
  text-decoration: none;
  margin: 0;
  padding: 0;
  outline: 0;
}
@-moz-keyframes fade-it {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-it {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-it {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-fade-in-verb {
  animation-name: fade-it;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fade-it;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
}
.shell {
  position: relative;
  width: 80%;
  margin: 25px auto;
}

h1 {
  position: relative;
  width: 100%;
  margin: 0 auto 50px auto;
  text-align: center;
  line-height: 1.25;
}
.row + .row {
  margin: 0;
}

.shell h1 {
  font-size: 60px;
}

@media only screen and (max-width: 600px) {
  .shell h1 {
    font-size: 35px;
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  p.intro {
    margin-bottom: 30px;
  }
}

.verb.js-block {
  display: inline-block;
}
.verb.js-hide {
  display: none;
  opacity: 0;
}
.verb {
  display: inline-block;
  visibility: visible;
  border-bottom: 1px solid;
}

.lightblue {
  background-color: #003a6d11;
}
.darkblue {
  background-color: #8234ce;
  color: white;
}
.zip-input /deep/ .v-input__slot {
  margin: 0 !important;
}

.zip-input /deep/ .v-text-field__details {
  display: none !important;
}
.zip-input /deep/ .v-input__append-outer {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.darkblue .paper {
  stroke: white !important;
}

h1,
h2 {
  font-family: Basetica-black;
}

h1 {
  font-size: 60px;
}
h2 {
  font-size: 44px;
}

.intro {
  font-size: 25px;
  font-weight: 500;
  padding-top: 6px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 0;
  vertical-align: middle;
}

.explainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.verb {
  display: inline !important;
  color: #2c25d3;
  border: none;
}
</style>

<script>
export default {
  name: "Home",
  async mounted() {
    // eslint-disable-next-line
    this.words().init();
  },
  methods: {
    words() {
      var words = ["convert", "sell", "earn", "get"],
        el = document.querySelector(".verb"),
        currentIndex = 0,
        currentWord,
        prevWord,
        duration = 3000;

      var _getIndex = function(max) {
        // currentIndex = Math.floor(Math.random() * (max - min + 1)) + min;
        currentIndex = currentIndex + 1;
        //Generates a random number between beginning and end of words array
        return currentIndex % (max + 1);
      };

      // var _getWord = function(index) {
      //   currentWord = words[index];

      //   return currentWord;
      // };

      var _clear = function() {
        setTimeout(function() {
          el.className = "verb";
        }, duration / 4);
      };

      var _toggleWord = function(duration) {
        setInterval(function() {
          //Stores value of previous word
          prevWord = currentWord;

          //Generate new current word
          // console.log(_getIndex(words.length - 1));
          currentWord = words[_getIndex(words.length - 1)];

          //Generate new word if prev matches current
          if (prevWord === currentWord) {
            currentWord = words[_getIndex(words.length - 1)];
          }

          //Swap new value
          el.innerHTML = currentWord;

          //Clear class styles
          _clear();

          //Fade in word
          el.classList.add("js-block", "js-fade-in-verb");
        }, duration);
      };

      var _init = function() {
        _toggleWord(duration);
      };

      //Public API
      return {
        init: function() {
          _init();
        },
      };
    },
  },
  components: {},
  data() {
    return {};
  },
};
</script>
