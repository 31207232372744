import Vue from "vue";
import Vuex from "vuex";
import gql from "graphql-tag";
import apolloClient from "../plugins/apollo";
// import * as Sentry from "@sentry/browser";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    next_route: null,
    user: {
      is_login: false,
      acl: 0, // legacy
      id: null,
      admin: false,
      acid: null,
      name: "",
      email: "",
      phone: "",
      street: "",
      city: "",
      state: "",
      zip: "",
    },
    snackbar: {
      show: false,
      text: "",
    },
    create_seminar: {
      step: 1,
      pricing: 0.44,
      campaign_id: null, // temp 1670
      venueName: null,
      venueAddress: null, // used for autocomplete state
      street: null,
      city: null,
      state: null,
      zip: null, // THIS IS THE VENUE ZIP CODE
      lat: 0,
      lng: 0,
      zips: [],
      email_disclosure: "",
      book_disclosure: "",
      appointment_dates: [],
      min_event_date_offset: 1000 * 60 * 60 * 24 * 35,
      events: [
        {
          date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 35)
            .toISOString()
            .slice(0, 10),
          time: "6:30 pm",
          capacity: null,
          aptDate1: null,
          aptDate2: null,
          aptDate3: null,
          aptDate4: null,
        },
      ],
      contentTemplate: "The Top 5 Way to Increase Annuity Results",
      background: "Steak Dinner",
      demographics: {
        distance: 30,
        income: 50000,
        minAge: 60,
        maxAge: 75,
        number_of_records: 0,
        request_id: null,
      },
      language: null,
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setExpire(state, payload) {
      state.expire = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    create_seminar_address(state, payload) {
      state.create_seminar.street = payload.name;
      state.create_seminar.city = payload.locality;
      state.create_seminar.state = payload.administrative_area_level_1;
      state.create_seminar.zip = payload.postal_code;
      state.create_seminar.lat = payload.latitude;
      state.create_seminar.lng = payload.longitude;
    },
    set_snackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.text = payload;
    },

    setup_current_user(state, payload) {
      state.user = {
        is_login: true,
        acl: 0, // legacy
        ...payload,
      };
    },
    signout(state) {
      state.user = {
        token: null,
        is_login: false,
        id: null,
        acl: null,
        admin: false,
        name: "",
        email: "",
      };
      localStorage.removeItem("token");
    },
    set_next_route(state, payload) {
      state.next_route = payload;
    },
  },
  actions: {
    async postFireBaseSignin({ commit }, payload) {
      let client = apolloClient.defaultClient;
      let { data } = await client.query({
        query: gql`
          query postFireBaseSignin($fbid: String!) {
            users(where: { fbid: { _eq: $fbid } }) {
              id
              acid
              admin
              email
              name
              phone
              street
              city
              state
              zip
              email_disclaimer
              book_disclaimer
            }
          }
        `,
        variables: {
          fbid: payload.uid,
        },
      });
      let user = data.users.length > 0 ? data.users[0] : null;
      if (!user) {
        // TODO: create user
        let { data } = await client.mutate({
          mutation: gql`
            mutation InsertUser(
              $fbid: String!
              $name: String!
              $email: String!
            ) {
              insert_users_one(
                object: { fbid: $fbid, name: $name, email: $email }
              ) {
                acid
                id
                name
                email
              }
            }
          `,
          variables: {
            fbid: payload.uid,
            name: payload.displayName || "",
            email: payload.email,
          },
        });
        user = data.insert_users_one;
      }
      console.log("USER", user);
      commit("setup_current_user", user);
    },
  },
  modules: {},
});
