import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// eslint-disable-next-line no-unused-vars
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  {
    path: "/presentation",
    name: "Presentation",
    component: () =>
      import(
        /* webpackChunkName: "Presentation" */ "../views/Presentation.vue"
      ),
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/auth/Login.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () =>
      import(/* webpackChunkName: "SignUp" */ "../views/auth/SignUp.vue"),
  },
  // {
  //   path: "/reset-password",
  //   name: "PasswordReset",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "PasswordReset" */ "../views/auth/PasswordReset.vue"
  //     ),
  // },
  // {
  //   path: "/reset-password/:token",
  //   name: "UpdatePassword",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "UpdatePassword" */ "../views/auth/UpdatePassword.vue"
  //     ),
  // },
  {
    path: "/seminar",
    name: "Seminar",
    component: () =>
      import(/* webpackChunkName: "Router" */ "../views/seminar/Router.vue"),
    children: [
      {
        path: "create",
        name: "CreateSeminar",
        component: () =>
          import(
            /* webpackChunkName: "CreateSeminar" */ "../views/seminar/CreateSeminar.vue"
          ),
      },
      {
        path: "success",
        name: "SeminarComplete",
        component: () =>
          import(
            /* webpackChunkName: "SeminarComplete" */ "../views/seminar/SeminarComplete.vue"
          ),
      },
      {
        path: "",
        name: "ListSeminars",
        component: () =>
          import(
            /* webpackChunkName: "ListSeminars" */ "../views/seminar/ListSeminars.vue"
          ),
      },
      {
        path: ":id",
        name: "SeminarDetails",
        component: () =>
          import(
            /* webpackChunkName: "SeminarDetails" */ "../views/seminar/SeminarDetails.vue"
          ),
      },
      {
        path: "households",
        name: "ListHouseholds",
        component: () =>
          import(
            /* webpackChunkName: "ListHouseholds" */ "../views/seminar/ListHouseholds.vue"
          ),
      },
      {
        path: "household/:id",
        name: "HouseholdDetail",
        component: () =>
          import(
            /* webpackChunkName: "HouseholdDetail" */ "../components/Seminar/HouseholdDetail.vue"
          ),
      },
    ],
  },

  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "Admin" */ "../views/admin/Admin.vue"),
    children: [
      {
        path: "agents",
        component: () =>
          import(
            /* webpackChunkName: "AdminAccounts" */ "../views/admin/Accounts.vue"
          ),
      },

      {
        path: "seminars",
        component: () =>
          import(
            /* webpackChunkName: "AdminListSeminars" */ "../views/admin/ListSeminars.vue"
          ),
      },
      {
        path: "events",
        component: () =>
          import(
            /* webpackChunkName: "AdminEventList" */ "../views/admin/EventList.vue"
          ),
      },
      {
        path: "events/:event_id",
        component: () =>
          import(
            /* webpackChunkName: "AdminEventDetail" */ "../views/admin/EventDetail.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  const publicPages = [
    "/",
    "/login",
    "/signup",
    "/markets",
    "/pricing",
    "/contact-us",
    "/presentation",
    "/reset-password",
  ];

  const authRequired = !(
    publicPages.includes(to.path) || to.path.search("reset-password") !== -1
  );

  if (authRequired && !localStorage.getItem("token")) {
    console.log("auth required");
    store.commit("set_next_route", to.path);
    return next("/login");
  }

  next();
});

export default router;
